<template>
  <div class="wa--dashboard">
    <h1 class="wind--title">
      概览
    </h1>
    <div class="wa--panel-container">
      <wa-big-number
        :number="user_total_count"
        description="用户总数">
      </wa-big-number>
      <wa-chart-panel
        dimension="1x1"
        :data="userAdmittedGauge"
        :options="gaugeChartOptions"
        title="通过审核用户"
        v-slot="{ data, options }">
        <ccv-gauge-chart :data="data" :options="options"></ccv-gauge-chart>
      </wa-chart-panel>
      <wa-big-number
        :number="farm_total_count"
        description="水电场总数">
      </wa-big-number>
      <wa-chart-panel
        dimension="1x1"
        :data="farmAdmittedGauge"
        :options="gaugeChartOptions"
        title="通过审核水电场"
        v-slot="{ data, options }">
        <ccv-gauge-chart :data="data" :options="options"></ccv-gauge-chart>
      </wa-chart-panel>
    </div>
    <hr class="wind--divider wind--divider--invisible" />
    <h1 class="wind--title">
      服务器
    </h1>
    <div class="wa--panel-container">
      <wa-status-panel :status="couchdb_status">
        CouchDB服务器
      </wa-status-panel>
      <wa-status-panel :status="windproc_status">
        计算服务器
      </wa-status-panel>
    </div>
  </div>
</template>
<script>
import { WaBigNumber, WaChartPanel, WaStatusPanel } from '../components'

export default {
  name: 'Dashboard',
  components: {
    WaBigNumber,
    WaChartPanel,
    WaStatusPanel
  },
  data: () => ({
    user_total_count: 0,
    user_admitted_ratio: 0,
    farm_total_count: 0,
    farm_admitted_ratio: 0,
    couchdb_status: '',
    windproc_status: '',
    gaugeChartOptions: {
      height: '80%',
      gauge: {
        type: 'full',
        alignment: 'center'
      }
    }
  }),
  computed: {
    userAdmittedGauge (){
      return [
        {
          group: 'value',
          value: this.user_admitted_ratio
        }
      ]
    },
    farmAdmittedGauge (){
      return [
        {
          group: 'value',
          value: this.farm_admitted_ratio
        }
      ]
    }
  },
  created (){
    this.$api.user.getUserStatistics().then( r => {
      if (r.ok) {
        this.user_total_count = r.data.total_count
        this.user_admitted_ratio = (r.data.status_breakdown.admitted_count / r.data.total_count * 100)
      }
    })

    this.$api.farm.getFarmStatistics().then( r => {
      if (r.ok) {
        this.farm_total_count = r.data.total_count
        this.farm_admitted_ratio = (r.data.status_breakdown.admitted_count / r.data.total_count * 100)
      }
    })

    this.$api.server.getDatabaseStatus().then( r => {
      if (r.ok) {
        this.couchdb_status = 'online'
      } else {
        this.couchdb_status = 'offline'
      }
    })

    this.$api.server.getWindprocStatus().then( r => {
      if (r.ok) {
        this.windproc_status = 'online'
      } else {
        this.windproc_status = 'offline'
      }
    })
  }
}
</script>